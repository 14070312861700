import { useEffect } from "react";

// Declare `ttq` as part of the global `window` object, with the correct type.
declare global {
  interface Window {
    ttq?: TTQ;
  }
}

// Define the type for the ttq object, including methods and properties.
interface TTQ {
  methods: string[];
  setAndDefer: (ttq: TTQ, method: string) => void;
  instance: (instanceId: string) => any;
  load: (pixelId: string, options?: any) => void;
  page: () => void;
  track: (event: string, data?: any) => void;
  queue: any[];
  callMethod?: (...args: any[]) => void;
  [key: string]: any; // Index signature for dynamic properties
}

const TikTokPixel = (): null => {
  useEffect(() => {
    // Initialize TikTok Pixel
    (function (w: Window, d: Document, t: string) {
      // Check if ttq is already defined
      if (typeof w.ttq !== "undefined") return;

      // Initialize ttq as an object with a function to handle method calls
      const ttq: TTQ = {
        methods: [
          "page",
          "track",
          "identify",
          "instances",
          "debug",
          "on",
          "off",
          "once",
          "ready",
          "alias",
          "group",
          "enableCookie",
          "disableCookie",
          "holdConsent",
          "revokeConsent",
          "grantConsent",
        ],
        queue: [],
        setAndDefer: function (t: TTQ, e: string) {
          t[e] = function () {
            t.queue.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        },
        instance: function (t: string) {
          let e; // Declare `e` outside the loop so it's accessible later
          for (let n = 0; n < ttq.methods.length; n++) {
            e = ttq._i[t] || []; // Assign `e` inside the loop
            ttq.setAndDefer(e, ttq.methods[n]);
          }
          return e; // Return `e` after the loop
        },
        load: function (e: string, n?: any) {
          const r = "https://analytics.tiktok.com/i18n/pixel/events.js";
          ttq._i = ttq._i || {};
          ttq._i[e] = [];
          ttq._i[e]._u = r;
          ttq._t = ttq._t || {};
          ttq._t[e] = +new Date();
          ttq._o = ttq._o || {};
          ttq._o[e] = n || {};

          const script = d.createElement("script") as HTMLScriptElement;
          script.type = "text/javascript";
          script.async = true;
          script.src = `${r}?sdkid=${e}&lib=${t}`;
          const firstScript = d.getElementsByTagName("script")[0];
          firstScript?.parentNode?.insertBefore(script, firstScript);
        },
        page: function () {
          // Default behavior for page tracking
          ttq.track("PageView");
        },
        track: function (event: string, data: any = {}) {
          // Actual tracking implementation
          console.log(`Tracking event: ${event}`, data);
          // You can call `window.ttq.push` or the event function here
        },
        callMethod: function (...args: any[]) {
          // Method to handle the function calls if available
          (ttq.callMethod ? ttq.callMethod.apply(ttq, args) : ttq.queue.push(args));
        },
      };

      console.log("!!!");
      // Set up deferred methods
      for (let i = 0; i < ttq.methods.length; i++) {
        ttq.setAndDefer(ttq, ttq.methods[i]);
      }

      // Attach ttq to the window object
      w.ttq = ttq;

      // Load the TikTok Pixel with your Pixel ID
      ttq.load("CUPP2QJC77U4QKJNFFAG"); // Replace with your TikTok Pixel ID
        ttq.page();
    })(window, document, "ttq");
  }, []);

  return null;
};

export default TikTokPixel;
