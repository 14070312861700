import { BoldLargeText, MediumText } from "../../utils/GlobalStyles";
import { CharacterStoryCardProps } from "../../utils/Interfaces/Game";
import parse from "html-react-parser";
import {
  CharacterStoryCardBox,
  CharacterStoryCardWrapper,
  Image,
  TextCard,
  TextInfoBox,
  UpperSectionBox,
} from "./styles";

export default function CharacterStoryCard(props: CharacterStoryCardProps) {
  const { character, casePath } = props;
  
  return (
    <>
      {character.story && (
        <CharacterStoryCardWrapper>
          <CharacterStoryCardBox>
            <UpperSectionBox>
              <Image
                width={200}
                height={200}
                src={`../../images/cases/${casePath}/characters/${character.imageUrl}`}
                alt={character.imageUrl}
              />
              <TextInfoBox>
                <BoldLargeText>{character.name}</BoldLargeText>
              </TextInfoBox>
            </UpperSectionBox>
            <TextCard mt={1}>
              <MediumText>{parse(character.story)}</MediumText>
            </TextCard>
          </CharacterStoryCardBox>
        </CharacterStoryCardWrapper>
      )}
    </>
  );
}
